import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { theme } from '../../../../../domain/theme';
import { adService } from '../../../../../domain/services/adService';
import { Actionable } from '../../Actionable';
import { t } from '../../../../../domain/services/configService';

const formatArray = (array, stack = []) => {
  array.forEach(item =>
    Array.isArray(item) ? formatArray(item, stack) : stack.push(array.join('x'))
  );
  return [...new Set(stack)];
};

export const AdInfo = ({ adBaseId, containerId, content }) => {
  const [isAdInfoVisible, setIsAdInfoVisible] = useState(false);

  const toggleAdInfo = () => {
    setIsAdInfoVisible(visible => !visible);
  };

  const styles = {
    container: css`
      position: absolute;
      z-index: 1;
    `,
    buttonAdInfo: css`
      background: #fc0;
      font-size: 10px;
      padding: 0 4px;
    `,
    containerAdInfo: css`
      border: 1px solid grey;
      font-size: 12px;
      padding: 20px;
      position: relative;
      text-align: left;
      width: 100%;
      max-width: 1200px;
      margin: 0 auto;
    `,
    bpList: css`
      list-style: disc;
      margin: 0 0 0 20px;
    `,
    adFormatList: css`
      list-style: circle;
      margin: 0 0 0 30px;
    `
  };

  const adInfoContent = theme.breakpoints.sorted.map(bp => {
    let adFormatsByBreakPoint =
      adService.getAdFormatsByBreakpoint()[adBaseId][bp.name];
    if (adFormatsByBreakPoint === null) {
      adFormatsByBreakPoint = [];
    } else if (!Array.isArray(adFormatsByBreakPoint)) {
      adFormatsByBreakPoint = [adFormatsByBreakPoint];
    }
    return (
      <ul key={bp.name}>
        <li css={styles.bpList}>
          {`${bp.name}:`}
          <ul>
            {adFormatsByBreakPoint.map(adFormat => {
              return (
                <li css={styles.adFormatList} key={adFormat + bp.name}>
                  {`${adFormat}: ${formatArray(
                    adService.getAdSizes()[adFormat]
                  ).join(' | ')}`}
                </li>
              );
            })}
          </ul>
        </li>
      </ul>
    );
  });

  const actionableTitle = !isAdInfoVisible ? 'Show ' : 'Hide ';
  return (
    <>
      <div css={styles.container}>
        <Actionable
          display="inline"
          title={t(actionableTitle)}
          onClick={toggleAdInfo}
        >
          <span css={styles.buttonAdInfo}>
            {actionableTitle}
            Ad info
          </span>
        </Actionable>
      </div>
      {isAdInfoVisible && (
        <div css={styles.containerAdInfo}>
          <p>
            <strong>Ad Unit Path: </strong>
            {adService.getAdUnitPath(content)}
          </p>
          <p>Targeting:</p>
          <blockquote>
            <pre>
              {JSON.stringify(
                adService.getTargeting(content, containerId),
                null,
                2
              )}
            </pre>
          </blockquote>
          <p>{`Ad ID: ${adBaseId}`}</p>
          <p>{`Container ID: ${containerId}`}</p>
          <p>Sizes:</p>
          {adInfoContent}
        </div>
      )}
    </>
  );
};
AdInfo.defaultProps = {
  content: undefined
};

AdInfo.propTypes = {
  adBaseId: PropTypes.string.isRequired,
  containerId: PropTypes.string.isRequired,
  content: PropTypes.objectOf(PropTypes.any)
};
